.username {
    font-family: 'Roboto';
    font-weight: 1000;
    color: #121111;
    font-size: 16;
    align-self: center;
    text-justify: center;
}
.bio {
    font-family: 'Roboto';
    font-weight: 800;
    color: #505050;
    font-size: 12;
    align-self: center;
}
.email{
    font-family: 'Roboto';
    font-weight: 550;
    color: #505050;
    font-size: 12;
    align-self: center;
}
