body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Proxima-Nova-Black';
  src: local('Proxima-Nova-Black'), url(./assets/Fonts/Proxima-Nova-Black.otf) format('truetype');
}
@font-face {
  font-family: 'Proxima-Nova';
  src: local('Proxima-Nova'), url(./assets/Fonts/Proxima-Nova.ttf) format('truetype');
}
@font-face {
  font-family: 'Pacifico-Regular';
  src: local('Pacifico-Regular'), url(./assets/Fonts/Pacifico-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Morgan-Chalk';
  src: local('Morgan-Chalk'), url(./assets/Fonts/Morgan-Chalk.ttf) format('truetype');
}
@font-face {
  font-family: 'Proxima-Condensed';
  src: local('Proxima-Condensed'), url(./assets/Fonts/Proxima-Condensed.ttf) format('truetype');
}
@font-face {
  font-family: 'Fab-Felt';
  src: local('fabfeltscript-bold'), url(./assets/Fonts/fabfeltscript-bold.ttf) format('truetype');
}