.headerFontHeader {
    font-family: Proxima-Nova-Black;
    /* color: #0004; */
    width: 80%;
    max-width: 700px;
    margin: 0 auto;
    color: #fff;
    text-shadow: 0 1px 4px rgba(0,0,0,0.1);
    /* display: inline-block; */
    vertical-align: middle;
    /* position: relative; */
    /* margin-top: 100px; */
    margin-bottom: 15px;
    font-size: 44px;  
    line-height: 48px;
    font-weight: 800;
    padding: 0;
    text-align: center;
    justify-content: center;
   

}
.headerFontSubtext{
    font-family: Proxima-Nova;
    /* color: #0004; */
    /* width: 80%;
    max-width: 700px;
   
     */
     margin: 0 auto;
    color: #fff;
    text-shadow: 0 1px 4px rgba(0,0,0,0.1);
    /* display: inline-block; */
    vertical-align: middle;
    /* position: relative; */
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    padding-top: .25em;
    padding-bottom: .25em;
    /* max-width: 800px; */
    width: 50%;
}
.supportURL{
    font-family: Proxima-Nova;
    /* color: #0004; */
    /* width: 80%;
    max-width: 700px;
   
     */
     margin: 0 auto;
    color:black;
    text-shadow: 0 1px 4px rgba(0,0,0,0.1);
    /* display: inline-block; */
    vertical-align: middle;
    /* position: relative; */
    /* text-align: center; */
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    padding-top: .25em;
    margin-left: 1rem;
    padding-bottom: .25em;
    border-top-left-radius: 5%;
    /* max-width: 800px; */
    /* width: 50%; */
}
.textContainer {
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}
.profileScreen {
    /* height: 100vw; */
    width: 100%;
    flex:1;
    /* height: 100vh; */
    background-color:#5F63BA;
 
}
.footerColumnHeader{
    font-family: Proxima-Nova-Black;
    /* text-shadow: 0 1px 4px rgba(0,0,0,0.1); */
    font-size: 20px;
    font-weight: 100;
    line-height: 28px;
    margin-bottom: 16px;
    text-align: left;
   

}
.footerText{
    text-align: left;
}

.links{
    text-decoration: none;
}
.links:hover{
    text-decoration: none;
}
.primary {
    color: orange;
}
.wrapper{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px,3fr));
    grid-row: auto rows 100px; 
    grid-gap:20px;
} 
.content-container{
    display: 'flex';

}
.content-container div{
    border: 1px #ccc solid;
    padding: 10px;

}

.column-3{
    display: flex;
    flex:3;
    flex-direction: column;
    max-width: 1250px;
    margin-bottom: 25px;
    margin-left: 25px;
    margin-right: 25px;
    min-width: 500px;

    

}

.box-2{
    /* background-color:chartreuse; */
    flex:2;
    margin: 10px;
   
    /* height: 500px; */
    /* width: 100%; */
    /* height: 100px;*/
} 
.box-3{
    background-color:teal;
    /* width: 100%; */
    /* height: 100px; */
}
.card{
    box-shadow: aqua;
    background-color: white;
    /* height: 200px;
    width: 100%; */
}
.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: flex-start;
    
    /* height: 200px;
    width: 100%; */
}
