.footer-text{
	color: ​#192947;
	text-decoration: none;
	display: block;
}
.footer-frame {
	width: 100%;
	height: 1.5rem;
}

.footer {
	padding-top: 3rem;
	/* padding-bottom: 0.5rem; */
	background-color: white;
	bottom: 1%;

	/* position: fixed; */
	width: 100%;
}

.footer .footer-col {
	margin-bottom: 2.25rem;
}

.footer h4 {
	margin-bottom: 0.625rem;
	color: #192947;
}

.footer .list-unstyled,
.footer p {
	color: #f3f7fd;
}


.footer .footer-col.middle .list-unstyled .fas {
	color: #fff;
	font-size: 0.5rem;
	line-height: 1.5rem;
}

.footer .footer-col.middle .list-unstyled .media-body {
	margin-left: 0.5rem;
}

.footer .footer-col.last .list-unstyled .fas {
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.footer .footer-col.last .list-unstyled .fab {
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.footer .footer-col.last .list-unstyled .media-body {
	margin-left: 0.625rem;
}

.footer .footer-col.last .list-unstyled .fas.fa-globe {
	margin-left: 1rem;
	margin-right: 0.625rem;
}

.ex-footer-frame {
	width: 100%;
	height: 2.75rem;
	background-color: #f3f7fd;
}

.ex-basic-2 .list-unstyled .media-body {
	margin-left: 0.625rem;
}


.footer-frame{

	fill:#5F63BA;
}